.cardheight {
  height: 252px;
}




@media only screen and (min-width: 1600px) {
  .cardheight {
    height: 233px;
  }
}
