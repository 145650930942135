/* apipurchaselog.module.css */

/* Card heading */
.mainheading {
  font-size: 13px; /* Adjust as needed */
  font-weight: 700;
}

/* Table headings */
.headingtable {
  color: #6b778c !important; /* Heading color */
  font-size: 13px; /* Font size for headings */
  background-color: transparent; /* Remove background color */
}

/* Table values */
.valuetable {
  font-size: 12px; /* Font size for table values */
}

.table {
  border-collapse: collapse;
}

.table tbody {
  border: none;
}

.table tbody tr {
  border: none;
}

.table tbody td {
  border: none;
}

.table th:first-child,
.table td:first-child {
  padding-left: 0px;
}
.table td:last-child {
  padding-bottom: 0px;
}

.errorratesub {
  font-size: 13px;
  color: #b2b2b5;
  font-weight: 400;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure the header background is opaque */
  z-index: 1; /* Ensure it stays above the tbody */
  /* Create space for the border at the bottom */
}

.stickyHeader::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height to match the border size */
  border-bottom: 0.5px solid #b2b2b5;
  z-index: 2; /* Ensure it is above other content */
}

thead {
  position: relative; /* Ensure pseudo-element positioning is relative to thead */
}

.table td,
.table th {
  border: none;
}
.tbody {
  display: block;
  max-height: 264px; /* Adjust height as needed */
  overflow-y: auto;
  width: 100%;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table td {
  padding-bottom: 7px;
}
