.stickyheader {
    position: sticky;
    top: 0;
    background: white; /* Optional: Background color to avoid transparency issues */
    z-index: 2; /* Optional: Ensure it stays above other content */
    padding: 10px 0px;
  }

  /* Custom column classes for the layout */
.colfirst {
  width: 17.66%;
margin-right: 10px;

}

.colsecond {
  width: 82.34%;

}

.makecol{
  display: flex;
  flex-direction: row;

}

/* Make it responsive for smaller screens */
@media (max-width: 768px) {
  .colfirst, .colsecond {
    width: 100% ; /* Stacks the columns */
    margin-bottom: 10px;
  }

.makecol{
  display: flex;
  flex-direction: column;

}
}