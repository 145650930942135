.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Roboto", sans-serif;
}

/* Overall Scrollbar CSS */
::-webkit-scrollbar {
  width: 3px;
  margin-left: 16px;
  height: 3px;
  scrollbar-width: none !important;
  overflow: scroll;
  scrollbar-color: rgba(26, 26, 26, 0.3) white;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #999fac;
  border-radius: 2px;
}

/* Styles for Tooltip */
.react-tooltip-lite {
  background-color: #3c4257 !important;
  color: #fff;
  border-radius: 5px !important;
  padding: 4px !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif;
  max-width: fit-content !important;
  width: fit-content !important;
  word-wrap: break-word !important;
  border-radius: 3px !important;
}

.daterangepicker td.in-range {
  background-color: #d7c7d2 !important;
}
.daterangepicker td.active {
  background-color: #581845 !important;
}

.btn.btn-primary {
  background-color: #581845 !important;
  border-color: #581845;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #39102d;
  border-color: #39102d;
}
.datepicker_dateInput__s6WM9:focus-within,
.datepicker_dateInput__s6WM9:focus {
  border: 1px solid #ccc !important;
}

.container {
  max-width: -webkit-fill-available !important;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #581845 !important;
  outline: 0;
  box-shadow: none !important;
}

.retryButton {
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  border: none !important;
  font-weight: bold !important;
  color: #fff !important;
  padding: 5px 12px;
}
select:focus-visible {
  outline: none;
  box-shadow: none;
}


.rmdp-week-day{
  color: #581845 !important;
}
.rmdp-week{
  color: #581845 !important;
  
}

.rmdp-panel .rmdp-day.rmdp-selected span {
  background-color: #581845 !important; /* Change the background color */
  color: white !important; /* Ensure the text is readable on the new background */
}

/* Optional: Change the hover color for selected dates */
.rmdp-panel .rmdp-day.rmdp-selected:hover span {
  background-color: #451436 !important; /* Slightly darker on hover */
  /* color: white !important; */
}
.rmdp-range {
  
  background-color: #d7c7d2 !important;
}

.rmdp-day.rmdp-today span{
  background-color: #581845 !important;
}

.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
  background-color: #581845 !important;
}
.rmdp-range {
  color: black !important;
}

.rmdp-arrow-container:hover{
  background-color: white !important;
 box-shadow: none !important;
}

.rmdp-arrow{
 border-color: #581845 !important;
}
.rmdp-shadow {
  z-index: 0 !important;
}

.customdateinput .rmdp-wrapper .rmdp-calendar-container {
  z-index: 0 !important; /* Ensure it is applied */
}

.customdateinput .rmdp-calendar {
  z-index: 0 !important;
}
.rmdp-calendar {
  /* Ensure positioning context */
  z-index: 0 !important; /* Set z-index */
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.ep-arrow  .rmdp-ep-arrow .rmdp-ep-shadow {
display: none !important;
} 

