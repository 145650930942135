/* apipurchaselog.module.css */

/* Card heading */
.mainheading {
    font-size: 13px; /* Adjust as needed */
    font-weight: 700;
   
}

/* Table headings */
.headingtable {
    color: #666666 !important; /* Heading color */
    font-size: 13px; /* Font size for headings */
    background-color: transparent; /* Remove background color */
}

/* Table values */
.valuetable {
      font-size: 12px; /* Font size for table values */
}

.table {
    border-collapse: collapse;
    
}

.table tbody {
    border: none;
}

.table tbody tr {
    border: none;
}

.table tbody td {
    border: none;
}

/* .table th:first-child,
.table td:first-child {

  

} */
.table td:last-child {
    padding-bottom: 0px;
}

.errorratesub{
    font-size: 13px;
    color: #B2B2B5;
    font-weight: 400;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background-color: white; /* Ensure the header background is opaque */
    z-index: 1; /* Ensure it stays above the tbody */
    /* Create space for the border at the bottom */
}
.headingtable:not(:last-child) {
    border-right: 0.5px solid #B2B2B5;/* Change the border style and color as needed */
  }
.stickyHeader::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Adjust the height to match the border size */
    /* border-bottom: 0.5px solid #B2B2B5; */
    z-index: 2; /* Ensure it is above other content */
}

thead {
    position: relative; /* Ensure pseudo-element positioning is relative to thead */
}

.table td, .table th {
    border: none; 
}
.tbody {
    display: block;
    max-height: 311px; /* Adjust height as needed */
    overflow-y: auto;
    width: 100%;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table td {
   padding-top:15px;
   padding-bottom: 15px;
}
.table td:last-child{
    padding-bottom:3px ;
   }

   .card {
    padding: 10px; 
    background-color: white; /* Card background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Initial box shadow */
    transition: box-shadow 0.3s; /* Smooth transition for hover effect */
    margin: 10px 0px 10px 0px;
  }
  /* .card:nth-child(odd) {
    background-color: #FAF7FA !important; 
} */
  /* .card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
    
  }
  .table tbody tr:hover td {
    background-color: #FAF7FA !important; 

  }
  
  .table tbody tr:hover td:first-child {
    border-left: 4px solid #581845; 
  } */

 


   
  .col-lg-first {
    width: 17.66%;
   
  }
  
  .col-lg-second {
    width: 82.34%;
  }

  @media (max-width: 576px) {
    .col-lg-first, .col-lg-second {
      width: 100%; /* Stack the columns */
    }
  }

.makeellips{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 205px;
}
 