.loginbtnloder {
  background-color: rgb(88, 24, 69) !important;
  font-size: 15px !important;
  height: 35px !important;
  width: 119px !important;
  font-weight: 700 !important;
  color: #fff !important;
  opacity: 0.8 !important;
}

.promoMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2e7ef; /* Example background color */
  padding: 10px;
  border: 1px solid #ddbcd3; /* Example border */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Space between messages */
  font-size: 13px;
}

.closeIcon {
  cursor: pointer;

  color: #581845;
  margin-right: 5px;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it appears above other content */
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  border-width: 0.3rem;
  color: #581845;
}

.form-control::placeholder{
  color: #888888 !important
}
