.sidebar {
  width: 200px;
  position: fixed;
  left: 0;
  background-color: #ffffff;
}

.sidebarIcon {
  margin-right: 10px;
}

.menuItem {
  padding: 0px 0px 5px 10px !important;
  border-radius: 5px;
  transition: none !important;
  margin-bottom: 10px;
  border: 1px solid white !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menuItemSelected {
  padding: 0px 0px 5px 10px !important;
  border-radius: 5px;
  transition: none !important;
  margin-bottom: 10px;
  border: 1px solid #d7c7d2 !important;
  background-color: #f2e7ef !important;
  color: #581845 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menuItemName {
  font-size: 13px !important;
}

.menuItem:hover {
  background-color: #f2e7ef !important;
}

.menuSignOut {
  margin: 2px 0px 0px 5px;
  font-size: 13px !important;
  font-weight: bold !important;
  color: #581845 !important;
  cursor: pointer;
}

.sideMenuList {
  padding: 0px 20px;
}

.pageLoader {
  width: 100%;
  background: rgba(0, 0, 0, 0.18);
  position: fixed;
  height: 100%;
  z-index: 99;
  top: 0;
  border: 0;
}