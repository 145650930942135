.notifiBlock {
    width: 450px !important;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    max-height: 375px;
    border-radius: 5px;
    padding: 10px;
    margin-top: 12px;
}

.messageLoader {
    display: flex;
    height: 40vh;
    width: 100%;
    justify-content: center;
}

.periodText {
    font-size: 13px !important;
    color: #6B778C !important;
    font-weight: bold;
    margin-top: 3px;
}

.markasRead {
    font-size: 13px !important;
    color: #581845 !important;
    cursor: pointer;
}

.messageToday {
    border-bottom: 1px solid #C9CACE;
    margin-bottom: 10px;
}

.messageYesterday {
    border-bottom: 1px solid #C9CACE;
    margin-bottom: 10px;
}

.messageOthers {
    border-bottom: 1px solid #C9CACE;
}

.markasReadDisable {
    font-size: 13px !important;
    color: #C9CACE !important;
    pointer-events: none;
}

.msgDesc {
    font-size: 13px !important;
    width: 70%;
}

.statusDiv {
    padding-right: 5px;
}

.msgTime {
    font-size: 13px !important;
    color: #6B778C;
    display: flex;
    justify-content: center;
}


.msgStatus {
    /* height: 10px; */
    height: 8px;
    background-color: #581845;
    /* width: 10px; */
    width: 8px;
    border-radius: 50%;
    display: flex;
    margin-top: 5px;
}

.msgBlock {
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.message {
    padding: 5px;
    border: 1px solid white;
    cursor: pointer;
}

.message:hover {
    background: #F2E7EF;
    border-radius: 5px;
    border: 1px solid #D7C7D2;
    padding: 5px;
    cursor: pointer;
}

.noMsgBlock {
    padding: 138px 0px
}

.noMsgText {
    font-size: 13px !important;
    margin-bottom: 5px;
}

.noMsgDesc {
    font-size: 13px !important;
    color: #999FAC !important;
}

.notificationTitle {
    font-size: 13px !important;
    font-weight: bold !important;
}

.switchText {
    font-size: 13px !important;
}

.switchComp {
    display: flex;
    padding: 0 !important;
    min-height: 0 !important;
    margin: 0 !important;
}

.inputSwitch {
    margin: 0 !important;
    padding: 0 !important;
    width: 30px !important;
    height: 17px !important;
    cursor: pointer !important
}

.inputSwitch:focus {
    box-shadow: none !important;
    border-color: #C9CACE !important;
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e) !important;
}

.inputSwitch:checked {
    border-color: #581845 !important;
    --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
}


.navNotification {
    padding-right: 10px;
}

.scrollbarContainer {
    overflow-y: scroll;
    max-height: 320px;
    position: relative;
}

.timeBlock {
    width: 30%;
}