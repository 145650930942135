.detailsheading {
  font-size: 13px;
  font-weight: 600;
}
.detailssubheading {
  font-size: 13px;
  color: #b2b2b5;
  margin-bottom: 13px;
}
.detailsvalue {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}
