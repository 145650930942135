.noinvoicetext {
    position: absolute;
    width: 251px;
    height: 15px;
    left: 480px;
    top: 310px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #999FAC;
}

.title {
    color: #6B778C;
    font-size: 13px;
    line-height: 15px;
    font-family: 'Roboto';
    /* font-style: normal; */
    display: flex;
    align-items: center;
    padding-left: 3px;
}


.input {
    /* position: absolute;
    width: 60px;
    height: 15px;
    left: 2047px;
    top: 18656px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px;
}

.overflow_input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
}



.hpadchange {
    padding: 0 !important;
    padding-bottom: 15px !important;
    padding-right: 15px !important;
    flex: 1 1 0%;
}

.padchange {
    padding: 0 !important;
    /* padding-bottom: 15px !important; */
    padding-right: 15px !important;
}

.padchanges {
    padding: 0 !important;
    /* padding-bottom: 15px !important; */
    padding-right: 15px !important;
    vertical-align: baseline !important;
    padding-top: 7.5px !important;
    border-top: 0.5px solid #a5889c !important;
    flex: 1 1 0%;
}


.unpaid {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #ED4857;
    margin-left: 4px;
    margin-left: 2px;
}

.paid {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #34CC65;
    /* margin-left: 10px; */
    padding: 5px;
}

.nodata {
    justify-content: center;
    margin-top: 187px;
    margin-right: 40px;
    display: flex;
    align-items: center;
}

.downloadinvoice {
    cursor: pointer;
    justify-content: flex-end;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px 56px 5px 5px;
}
.downloadspin {
    cursor: pointer;
    justify-content: flex-end;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px 56px 5px 5px;
}

.hypen {

    /* /* padding-left: 40px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 35px;
}

.billingdate {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px;
    /* margin-left: 15px; */
}

.paymentdate {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 5px;
    /* margin-left: 8px; */
}


.trtag {
    /* justify-content: center;  */
    border-bottom-width: none;
    padding: 0px 0px 5px 0px !important;
    display: flex;
}

.table td {
    border: none !important;
}

.invoicetitle{
    color: #6B778C;
    font-size: 13px;
    line-height: 15px;
    font-family: 'Roboto';
    /* font-style: normal; */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.mainheading{
    font-size: 20px;
    font-weight:700 ;
    margin-left: 8px;
}

.loderboder {
  --bs-spinner-border-width: 2px;
}