.bgimage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 12/6.3;
  /* height: auto; */
  /* Adjust as needed */
  /* width: 100%; */
  /* Adjust as needed */
}
.rights {
  color: rgb(88, 24, 69);
  margin-top: 35px;
  /* bottom: 10%;
  position: absolute;
  left: 50%; */
  /* transform: translateX(-50%);
  width: 100%; */
}
.rightsconformation {
  color: rgb(88, 24, 69);
  bottom: 25%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.loginbtn {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  height: 35px !important;
  width: 100% !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
}
.loginbtnloder {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  height: 35px !important;
  width: 100% !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
  /* opacity: 0.5 !important; */
}
.loderboder {
  border: 2px solid white !important;
  border-right-color: transparent !important;
  opacity: 1 !important;
}
.textbox:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #581845 !important;
  outline: 0;
  box-shadow: none !important;
}
.textbox1:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #581845 !important;
  outline: 0;
  box-shadow: none !important;
  color: black !important;
  opacity: 0.45 !important;
}

@media (max-width: 768px) {
  .bgimage {
    background-size: contain;
  }
}

.inputWrapper {
  position: relative;
  max-width: 600px;
}

.passwordToggle {
  /* transform: translateY(-50%); */
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px; /* Adjust the icon size */
  padding: 0;
  padding-right: 15px;
}

.textbox {
  width: 100%;
  padding-right: 40px; /* Ensure space for the icon/button */
}
.textbox1 {
  width: 100%;
  padding-right: 40px; /* Ensure space for the icon/button */
  color: black;
  /* opacity: 0.45 !important; */
}

.otploderboder {
  border: 1px solid black !important;
  border-right-color: transparent !important;
  opacity: 1 !important;
}
