.navbarElement {
  background-color: #ffffff;
}

.iconsFlex {
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  justify-content: space-between;
  position: relative,
}

.dropdownIcons {
  position: absolute;
  right: 0px;
  top: 20px;
}

.bellIcon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 30px;
}

.navHelpIcon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 0px;
}

.userNameText {
  font-size: 13px;
  text-transform: capitalize;
  max-width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 15px;
}

.pageLoader {
  width: 100%;
  background: rgba(0, 0, 0, .18);
}

.navbarUser {
  padding: 10px 10px 10px 0px;
  cursor: pointer;
}

.navbarUserIcon {
  margin-right: 10px;
  padding: 2px 7px 6px 8px;
  background: #581845;
  border-radius: 50%;
}

.splitLine {
  border-right: 1px solid #c9cace;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.navNotification {
  padding: 1px 5px 0px;
  cursor: pointer;
}

.navHelp {
  padding: 0px 5px;
  cursor: pointer;
}

.unreadCountStyle {
  position: absolute;
  left: 14px;
  bottom: 10px;
  background-color: var(--color-error, #f94949);
  border-radius: 100%;
  z-index: 11;
  display: flex;
  height: 21px;
  width: 21px;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.userDropdown {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 58px;
  right: 0px;
  margin-right: 10px;
}

.userMenuName {
  border: 1px solid white !important;
  border-radius: 5px;
  /* padding: 5px 20px !important; */
  padding: 5px 10px !important;
  margin-bottom: 5px;
  font-size: 13px !important;
  cursor: pointer;
}

.userMenuName:hover {
  background-color: #f2e7ef !important;
  color: #581845 !important;
  cursor: pointer;
}

.userMenuIcon {
  margin-right: 5px;
}

.userMenuoptionSection {
  padding: 5px 10px 0px 10px;
  margin: 0;
  border-bottom: 0.5px solid #c9cace;
}

.userSignOut {
  font-weight: bold !important;
  color: #581845 !important;
  font-size: 13px !important;
  margin-left: 5px;
}

.menuUserSignOut {
  /* padding: 10px 60px !important; */
  padding: 10px 45px !important;
  cursor: pointer;
}

.helpDropdown {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 5px;
  /* padding: 10px 10px 4px 10px; */
  padding: 5px 5px 0px 5px;
  margin-top: 12px;
}

.helpMenuName {
  border: 1px solid white !important;
  border-radius: 5px;
  /* padding: 5px 15px !important; */
  padding: 5px 10px !important;
  margin-bottom: 6px;
  font-size: 13px !important;
  cursor: pointer;
}

.helpMenuName:hover {
  background-color: #f2e7ef !important;
  cursor: pointer;
}

.helpMenuIcon {
  margin-right: 5px;
}

.helpMenuOption {
  margin-top: 1px;
}

.pageLoader {
  width: 100%;
  background: rgba(0, 0, 0, 0.18);
  position: fixed;
  height: 100%;
  z-index: 99;
  top: 0px;
  border: 0px;
}