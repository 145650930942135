.authenticationbtnloder {
  border-radius: var(--bs-border-radius-sm) !important;
  background-color: rgb(88, 24, 69) !important;
  color: #fff !important;
  /* opacity: 0.5 !important; */
  position: relative;
  /* font-size: 13px !important;
    font-weight: 600 !important; */
}

.loderboder {
  border: 2px solid white !important;
  border-right-color: transparent !important;
  opacity: 1 !important;
  position: absolute;
  top: 30%;
  left: 45%;
}

.authenticationbtn {
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: var(--bs-border-radius-sm) !important;
  background-color: rgb(88, 24, 69) !important;
  color: #fff !important;
}
