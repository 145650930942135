.headingtable {
  color: #6b778c !important; /* Heading color */
  font-size: 13px; /* Font size for headings */
  background-color: transparent; /* Remove background color */
}

.valuetable {
  font-size: 13px; /* Font size for table values */
}

.table {
  border-collapse: collapse;
}

.table tbody {
  border: none;
}

.table tbody tr {
  margin-bottom: 15px; /* Add margin-bottom to table rows */
}

.table tbody td {
  border: none;
  padding: 8px; /* Adjust padding as needed */
}

.table th:first-child,
.table td:first-child {
  padding-left: 0px;
}

.table td:last-child {
  padding-bottom: 0px;
}

.errorratesub {
  font-size: 13px;
  color: #b2b2b5;
  font-weight: 400;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure the header background is opaque */
  z-index: 1; /* Ensure it stays above the tbody */
  /* Create space for the border at the bottom */
}

.stickyHeader::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height to match the border size */
  border-bottom: 0.5px solid #b2b2b5;
  z-index: 2; /* Ensure it is above other content */
}

.name {
  font-weight: 700;
  font-size: 13px;
  color: #581845 !important;
  cursor: pointer;
}

.view {
  margin-right: 10px;
  cursor: pointer;
}

.noreport {
  font-size: 13px;
  font-weight: 400;
  color: #999fac;
}
