.mainheading {
  font-size: 13px; /* Adjust as needed */
  font-weight: 700;
  margin-bottom: 0px;
}
.subheading {
  font-size: 13px;
  color: #b2b2b5;
  font-weight: 400;
}
.downloadbtnloder {
  /* border-radius: var(--bs-border-radius-lg) !important; */
 
  font-size: 15px !important;
  border-radius: 5px;
  height: 30px !important;
  width: 112px !important;
  border: none !important;
  font-weight: 700 !important;
  color: #581845 !important;
  --bs-spinner-border-width: 2px !important;
  border: 2px solid #581845 !important;

  /* border-radius: var(--bs-border-radius-lg) !important;
    --bs-spinner-border-width: 2px;
    border: var(--bs-spinner-border-width) solid #581845;
    border-right-color: transparent; */
}

.downloadbtn {
  /* border-radius: var(--bs-border-radius-lg) !important; */
  /* background-color:  !important; */
  height: 30px !important;
  border-radius: 5px;
  font-size: 13px !important;
  height: 30px !important;
  width: 100% !important;
  font-weight: 700 !important;
  color: #581845 !important;
  border: 2px solid #581845 !important;
}

.loderboder {
  --bs-spinner-border-width: 2px !important;
  /* border-right-color: transparent !important; */

}


