.customdateinput {
    font-size: 13px;
    padding: 5px;
    height: 30px;
    width: 190px; /* Adjust width if needed */
    border: 1px solid #D7C7D2;
    border-radius: 5px;
  }
  .customdateinput:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }