.custom-border {
    border-color: #C9CACE !important; /* Use the desired color */
}
.detailsheading{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 18px;
}
.detailssubheading{
    font-size: 13px;
    color: #B2B2B5;
    margin-bottom: 13px;
}
.detailsvalue{
    color: #333333;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
}
