.phoneInput {
  /* border: 1px solid var(--color-gary-5, #cccccc) !important; */
  width: 100% !important;
  height: 31px !important;
  border-radius: 0px !important;
  background: transparent !important;
  border: none !important;
  border-bottom: thin solid #c9cace !important;
  outline: none !important;
  color: black;
}

.phoneInput:hover {
  border: none !important;
  border-bottom: thin solid #c9cace !important;
  outline: none !important;
}

.phoneInput:focus {
  border: none !important;
  border-bottom: thin solid #581845 !important;
  outline: none !important;
  box-shadow: none;
  color: black;
}

.dropDownStyle {
  margin: 2px !important;
  width: 220px !important;
  background-color: #ffffff !important;
  text-align: left !important;
}

.textbox {
  border: none;
  outline: none;
  border-bottom: thin solid #c9cace;
  background: transparent;
  border-radius: 0px;
  padding: 3px 12px 3px 0px;
}

.textbox:focus {
  border: none;
  outline: none;
  border-bottom: thin solid #c9cace;
}

.textbox:hover {
  border: none;
  outline: none;
  border-bottom: thin solid #c9cace;
}

.textbox,
.flag_dropdown {
  background-color: #ffffff !important;
}

.bgimage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 12/6.3;
}

.loginbtn {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  height: 35px !important;
  width: 90% !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.loginbtnloder {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  height: 35px !important;
  width: 90% !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
  /* opacity: 0.5 !important; */
}

@media (min-width: 770px) {
  .mobile {
    width: 570px !important;
  }
}

@media (max-width: 770px) {
  .mobpad {
    margin-top: 25px;
  }
}

.passwordToggle {
  /* transform: translateY(-50%); */
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  /* Adjust the icon size */
  padding: 0;
  padding-right: 15px;
}

.loderboder {
  border: 2px solid white !important;
  border-right-color: transparent !important;
  opacity: 1 !important;
}

.successbtn {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 13px !important;
  height: 35px !important;
  width: 120px !important;
  border: none !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.rights {
  color: rgb(88, 24, 69);
  margin-top: 35px;
  /* bottom: 10%;
  position: absolute;
  left: 50%; */
  /* transform: translateX(-50%);
  width: 100%; */
}
.rightsconformation {
  color: rgb(88, 24, 69);
  bottom: 25%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.verifybtn {
  border-radius: 5px !important;
  background-color: rgb(88, 24, 69) !important;
  font-size: 15px !important;
  height: 35px !important;
  width: 100% !important;
  border: none !important;
  font-weight: 500 !important;
  color: #fff !important;
}
.textbox:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #581845 !important;
  outline: 0;
  box-shadow: none !important;
}
.inputWrapper {
  position: relative;
  max-width: 600px;
}

.passwordToggle {
  /* transform: translateY(-50%); */
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px; /* Adjust the icon size */
  padding: 0;
  padding-right: 15px;
}

.textbox {
  width: 100%;
  padding-right: 40px; /* Ensure space for the icon/button */
}

.otploderboder {
  border: 1px solid black !important;
  border-right-color: transparent !important;
  opacity: 1 !important;
}
