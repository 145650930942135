.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1040; /* Ensure this is higher than your modal's z-index */
}
.modal-content {
  border: none !important;
}
.modal {
  --bs-modal-width: 371px !important;
}
.modal-dialog {
  position: relative;
  z-index: 1050;
}
.text-danger {
  color: #ed4857;
  font-size: 12px;
}

.current {
  height: 34px;
  max-width: 310px;
  font-size: 13px;
  border: 1px solid #d7c7d2;
}
